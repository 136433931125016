const axios = require('axios');
const { baseUrlNew, baseUrlServerLess, buildAuthHeaders } = require('./settings.js');

exports.fetchJourneyList = function (params) {
  return axios.post(baseUrlNew + `journey`, params, {
    headers: buildAuthHeaders()
  });
};

exports.fetchJourneyListAnalytics = function (params) {
  return axios.post(baseUrlServerLess + `journey/list/stats`, params, {
    headers: buildAuthHeaders()
  });
  // return axios.post(baseUrlNew + `journey/list/stats`, params, {
  //   headers: buildAuthHeaders()
  // });
};

exports.fetchStatusWiseCount = function (params) {
  return axios.post(baseUrlNew + `journey/status-count`, params, {
    headers: buildAuthHeaders()
  });
};

exports.fetchJourneyListForScheduledReports = function () {
  return axios.get(baseUrlNew + `journey/for-report`, {
    headers: buildAuthHeaders()
  });
};

exports.getDetails = function (id) {
  return axios.get(baseUrlNew + `journey/${id}`, {
    headers: buildAuthHeaders()
  });
};

exports.getStatsInRange = function (id, params) {
  return axios.post(baseUrlNew + `journey-report/stats-in-range/${id}`, params, {
    headers: buildAuthHeaders()
  });
};

exports.getNodeStats = function (id, params) {
  return axios.post(baseUrlNew + `journey-report/node-stats/${id}`, params, {
    headers: buildAuthHeaders()
  });
};

exports.getJourneyCampaignStats = function (id, params) {
  return axios.post(baseUrlNew + `journey-report/campaign/stats/${id}`, params, {
    headers: buildAuthHeaders()
  });
};

exports.getCustomersInJourney = function (id, params) {
  return axios.post(baseUrlNew + `journey-report/customers-in-journey/${id}`, params, {
    headers: buildAuthHeaders()
  });
};

exports.getJourneyEngagementStats = function (id, params) {
  return axios.post(baseUrlNew + `journey-report/engagement-stats/${id}`, params, {
    headers: buildAuthHeaders()
  });
};

exports.createDraft = function (params) {
  return axios.post(baseUrlNew + `journey/draft`, params, {
    headers: buildAuthHeaders()
  });
};

exports.cloneJourney = function (journeyId) {
  return axios.post(
    baseUrlNew + `journey/clone/${journeyId}`,
    {},
    {
      headers: buildAuthHeaders()
    }
  );
};

exports.updateDraft = function (journeyId, params) {
  return axios.put(baseUrlNew + `journey/draft/${journeyId}`, params, {
    headers: buildAuthHeaders()
  });
};

exports.publish = function (params) {
  return axios.put(baseUrlNew + `journey/publish`, params, {
    headers: buildAuthHeaders()
  });
};

exports.changePublishedJourneyStepContent = function (journeyId, data) {
  return axios.put(baseUrlNew + `journey/step/${journeyId}`, data, {
    headers: buildAuthHeaders()
  });
};

exports.changePublishedJourneyLinkContent = function (journeyId, data) {
  console.log('api call', journeyId, data);
  return axios.put(baseUrlNew + `journey/link/${journeyId}`, data, {
    headers: buildAuthHeaders()
  });
};

exports.updateJourneySettings = function (journeyId, data) {
  return axios.put(baseUrlNew + `journey/settings/${journeyId}`, data, {
    headers: buildAuthHeaders()
  });
};

exports.updateJourneyName = function (journeyId, data) {
  return axios.put(baseUrlNew + `journey/name/${journeyId}`, data, {
    headers: buildAuthHeaders()
  });
};

exports.deleteJourney = function (journeyId) {
  return axios.delete(baseUrlNew + `journey/${journeyId}`, {
    headers: buildAuthHeaders()
  });
};

exports.suspendJourney = function (journeyId) {
  return axios.put(
    baseUrlNew + `journey/suspend/${journeyId}`,
    {},
    {
      headers: buildAuthHeaders()
    }
  );
};

exports.endJourney = function (journeyId) {
  return axios.put(
    baseUrlNew + `journey/end/${journeyId}`,
    {},
    {
      headers: buildAuthHeaders()
    }
  );
};

exports.getJourneyTemplateData = function (templateId) {
  return axios.get(baseUrlNew + `journey/template/${templateId}`, {
    headers: buildAuthHeaders()
  });
};
